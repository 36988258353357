import { createI18n, /** LocaleMessageDictionary **/ } from 'vue-i18n'

const loadLocalesData = () => {
	const locales = (require).context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const localeData = {
		datetimeFormats: {},
		numberFormats: {},
		messages: {}
	}
	const keys = locales.keys()

	keys.forEach((key) => {
		const matched = key.match(/\.\/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			localeData.messages[locale] = locales(key)
		}
	})

	return localeData
}

const data = loadLocalesData()

function customRulePL(choice, choicesLength, orgRule){
	if (choice === 0)
		return 0
	
	if (choice === 1) 
		return 1
	
	if(choice >= 2 && choice <= 4)
		return 2
	
	return choice >= 5 ? 0 : 3
}

function customRuleRU(choice, choicesLength, orgRule) {
	if (choice === 0) {
	  return 0
	}
  
	const teen = choice > 10 && choice < 20
	const endsWithOne = choice % 10 === 1
	if (!teen && endsWithOne) {
	  return 1
	}
	if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
	  return 2
	}
  
	return choicesLength < 4 ? 2 : 3
  }

export const i18n = createI18n({
	locale: 'lv-LV',
	fallbackLocale: 'en-US',
	messages: data.messages,
	datetimeFormats: data.datetimeFormats,
	numberFormats: data.numberFormats,
	fallbackWarn: false,
	missingWarn: false,
	legacy: true,
	pluralizationRules: {
		'pl-PL': customRulePL
	},
})


export const availableLocales = [
{
	name: 'Polski',
	locale: 'pl-PL',
	flag: 'pl',
	selected: true
}, {
	name: 'USA',
	locale: 'en-US',
	flag: 'us',
	selected: false
}, {
	name: 'Łotewski',
	locale: 'lv-LV',
	flag: 'lv',
	selected: false
}
]

export default i18n